import Layout from "components/common/layout/layout";
import { SEO } from "components/common/SEO";
import { graphql } from "gatsby";
import React from "react";

import ActionRatio from "../components/actionRatio/ActionRatio";
import Bonus from "../components/bonus/Bonus";
import LocationMarkupSchema from "../components/common/SEO/LocationMarkupSchema";
import Corporate from "../components/corporate/corporate";
import Gallery from "../components/gallery/Gallery";
import HeroPage from "../components/heroPage/HeroPage";
import Services from "../components/services/services";
import SliderSegments from "../components/sliderSegments/SliderSegments";

const PageSegment = ({ pageContext, data }) => {
  const segment = data.sanitySegment;
  const allSegments = data.allSanitySegment;

  return (
    <Layout>
      <section className={"is-z1"}>
        <HeroPage hero={segment.hero} />
      </section>
      <section className={"is-z2"}>
        <Bonus
          title={segment.title}
          desc={segment.desc}
          goals={segment.bonus}
        />
      </section>
      <section className={"is-relative"}>
        <div className={"a-radar a-radar--left"}></div>
      </section>
      <Corporate
        slogan={segment.slogan}
        features={segment.features}
        className={"o-corportate--segment"}
      />
      <Services services={segment.services} />
      <Gallery photos={segment.gallery} />
      <SliderSegments segments={allSegments} />
      <ActionRatio />
    </Layout>
  );
};

export default PageSegment;

export const Head = ({ data }) => (
  <SEO
    title={`${data.sanitySegment.seo.title.es_AR}`}
    description={data.sanitySegment.seo.desc.es_AR}
    robots="index, follow"
    canonical={`${data.sanitySegment.slug.es_AR}/`}
  />
);

export const query = graphql`
  query ServicesInSegment(
    $id: String = "-44e4b115-e6bd-5144-8896-01ad0751b155"
  ) {
    sanitySegment(id: { eq: $id }) {
      slug {
        es_AR
        es_AR
      }
      services {
        Cotenido {
          img {
            asset {
              gatsbyImageData
              id
              _id
            }
          }
          alt {
            es_AR
            es_AR
          }
          desc {
            es_AR {
              children {
                text
              }
            }
            _rawEsAr
          }
          title {
            _rawEsAr
            es_AR {
              children {
                text
              }
            }
          }
        }
        service {
          has_ready
          slug {
            es_AR
            es_AR
          }
        }
      }
      hero {
        img {
          asset {
            _id
            id
            gatsbyImageData(
              width: 1197
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        desc {
          _rawEsAr
          es_AR {
            children {
              text
            }
          }
        }
        alt {
          es_AR
        }
        title {
          _rawEsAr
          es_AR {
            children {
              text
            }
          }
        }
      }
      title {
        es_AR
        es_AR
      }
      desc {
        _rawEsAr
        es_AR {
          children {
            text
          }
        }
      }
      bonus {
        title {
          es_AR
          es_AR
        }
        items {
          es_AR
          es_AR
        }
      }
      slogan {
        title {
          _rawEsAr
          es_AR {
            children {
              text
            }
          }
        }
        desc {
          _rawEsAr
          es_AR {
            children {
              text
            }
          }
        }
      }
      gallery {
        asset {
          gatsbyImageData
          id
          _id
        }
      }
      seo {
        title {
          es_AR
          es_AR
        }
        desc {
          es_AR
          es_AR
        }
        canonical {
          es_AR
          es_AR
        }
        robots
      }
    }
    allSanitySegment(filter: { id: { nin: [$id] }, has_ready: { eq: true } }) {
      edges {
        node {
          has_ready
          slug {
            es_AR
            es_AR
          }
          card {
            title {
              es_AR {
                children {
                  text
                }
              }
            }
            img {
              asset {
                gatsbyImageData
                id
                _id
              }
            }
          }
        }
      }
    }
  }
`;
