import "./corportate.scss";

import BlockContent from "@sanity/block-content-to-react";
import React from "react";

import Features from "./features/Features";

const Corporate: React.FC<any> = (props: {
  slogan: any;
  features: any;
  className: any;
}) => {
  if (!props.slogan) {
    return;
  }
  return (
    <div className={`o-corportate ${props.className}`}>
      <div className="m-slogan">
        <h2 className={"a-title a-title--body a-underline text-center"}>
          {/*<BlockContent blocks={props.slogan.title._rawEsAr} />*/}
          {props.slogan.title.es_AR[0].children[0].text}
        </h2>
        {!props.slogan.desc === null && (
          <p className={"corporate-text text-center"}>
            <BlockContent blocks={props.slogan.desc._rawEsAr} />
            {/*{props.slogan.desc.es_AR[0].children[0].text}*/}
          </p>
        )}
      </div>
      {props.features && <Features items={props.features} />}
    </div>
  );
};

export default Corporate;
