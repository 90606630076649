import "./features.scss";

import SanityImage from "gatsby-plugin-sanity-image";
import React from "react";

const Features: React.FC<any> = (props: { items: any }) => {
  const items = [];

  props.items.forEach((item, index) => {
    items.push(
      <div className={"a-item--feature"} key={index}>
        <div className={"a-item-feature__icon"}>
          <SanityImage
            {...item.img}
            alt={item.title.es_AR}
            width={60}
            height={60}
            style={{
              width: "60px",
              height: "60px",
              objectFit: "cover",
            }}
          />
        </div>
        <h3 className={"a-title a-title--feature "}>{item.title.es_AR}</h3>
        <p className={"content-feature-text"}>{item.desc.es_AR}</p>
      </div>
    );
  });

  return (
    <div className={"m-features grid-x3 grid-x3--break-tablet"}>{items}</div>
  );
};

export default Features;
