import "./goals.scss";

import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { useTranslation } from "react-i18next";

import check from "./check.svg";

const Goals: React.FC<any> = (props) => {
  const { t } = useTranslation("global");
  if (!props.bonus) {
    return true;
  }
  const items = [];

  if (props.bonus) {
    props.bonus.items.forEach((item, index) => {
      items.push(
        <li key={index} className={"a-item__goal"}>
          <img
            src={check}
            width={"29"}
            loading={"lazy"}
            aria-label={"hidden"}
          />
          <span className="a-item__text">{item.es_AR}</span>
        </li>
      );
    });
  }

  return (
    <div className={"m-goals"}>
      <h2 className={"a-title a-title--goals"}>{props.bonus.title.es_AR}</h2>
      <ul className={"a-list--goals"}>{items}</ul>
      <AnchorLink
        href="#contact-form"
        className={"a-button a-button--arrow m-goals__button"}
      >
        {t("heroHome.cta")}
      </AnchorLink>
    </div>
  );
};

export default Goals;
