import "./services.scss";

import BlockContent from "@sanity/block-content-to-react";
import { Link } from "gatsby";
import SanityImage from "gatsby-plugin-sanity-image";
import React from "react";
import { useTranslation } from "react-i18next";

const Services: React.FC<any> = (props: { services: any }) => {
  const { t } = useTranslation("global");
  const services = [];
  props.services.forEach((service, index) => {
    services.push(
      <section className={`m-service`} key={index}>
        <div className={`m-service__block-text`}>
          <p className={"a-title a-title--service is-orange"}>
            {service.Cotenido.title.es_AR[0].children[0].text}{" "}
          </p>
          <BlockContent blocks={service.Cotenido.desc._rawEsAr} />
          <br />
          {service.service && (
            <Link
              className={`a-button a-button--arrow`}
              to={`/${service.service.slug.es_AR}/`}
            >
              {" "}
              {t("services.cta")}
            </Link>
          )}
          {/*<Link className={`a-button a-button--arrow`} to={`/${service.service.slug.[`${i18n.language}`]}`}> {t("services.cta")}</Link>*/}
        </div>
        <div className={"m-service__img"}>
          <SanityImage
            {...service.Cotenido.img}
            width={583}
            alt={service.Cotenido.title.es_AR[0].children[0].text}
          />
        </div>
      </section>
    );
  });
  return <div className={"container o-services"}>{services}</div>;
};

export default Services;
