import "./bonus.scss";

import BlockContent from "@sanity/block-content-to-react";
import React from "react";
import { useTranslation } from "react-i18next";

import Goals from "./goals/Goals";

const Bonus: React.FC = (props) => {
  const { t } = useTranslation("bonus");
  return (
    <div className={"o-bonus"}>
      <div className="container grid-x2 grid-x2--bonus">
        <section>
          <h2 className={"a-title a-title--body a-underline--left"}>
            {props.title.es_AR}{" "}
          </h2>
          <BlockContent blocks={props.desc._rawEsAr} />
          {/*<p>{props.desc.es_AR}</p>*/}
        </section>
        <section className={"align-right"}>
          <Goals bonus={props.goals} />
        </section>
      </div>
    </div>
  );
};

export default Bonus;
